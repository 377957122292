"use client"; // Error components must be Client Components

import "./error.css";

import { GetPathLanguage, useTranslation } from "@/i18n/client";
import React from "react";
import { Trans } from "react-i18next";

export default function ErrorPage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}): React.JSX.Element {
  const language = GetPathLanguage();
  const lang = language || "en";

  const { t } = useTranslation(lang, "error");

  return (
    <div className="container error-container pb-4">
      <h1>
        <Trans i18nKey="title" t={t} />
      </h1>
      <h2>
        <Trans i18nKey="subtitle" t={t} />
      </h2>
      <p>
        <Trans i18nKey="text" t={t} />
      </p>
      <p hidden={true}>{"" + error}</p>
      <button className="btn btn-primary" onClick={() => reset()}>
        <Trans i18nKey="retry" t={t} />
      </button>
    </div>
  );
}
